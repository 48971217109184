import moment, {Moment} from 'moment';
import {default as momenttz} from 'moment-timezone'

// UI is used to represent a date to user
export const UI_DATE_FORMAT = "DD/MM/YYYY"
export const UI_TIME_FORMAT = "HH:mm:ss"
export const UI_TIME_FORMAT_HHMM = "HH:mm"

export const DATE_FORMAT = "YYYY-MM-DD"
export const UTC_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss"
export const DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZZ"

export const TS_FORMAT = "YYYY-MM-DD HH:mm:ss"
export const T_FORMAT = "YYYY-MM-DD HH:mm"

export class MomentUtils {
    static now(): moment.Moment {
        return moment()
    }

    static getTimeZone(): string {
        return momenttz.tz.guess()
    }

    static fromString(string: string | undefined, format: string = DATE_FORMAT): moment.Moment | undefined {
        if (!string) {
            return undefined
        }
        return moment(string, format)
    }

}

export class DateUtils {
    static now(): Date {
        return moment().toDate()
    }

    static nowMinusHours(hours: number): Date {
        return moment().subtract({'hours': hours}).toDate()
    }

    static nowPlusMonths(months: number): Date {
        return moment().add({'months': months}).toDate()
    }

    static fromDate(text: string | undefined): Date | undefined {
        if (text) {
            return moment(text, DATE_FORMAT).toDate()
        } else {
            return undefined
        }
    }

    static fromDateTime(text: string | undefined): Date {
        return moment(text, UTC_DATETIME_FORMAT).toDate()
    }

    static fromUtcDateTime(text: string | undefined): Date {
        return moment.utc(text, UTC_DATETIME_FORMAT).toDate()
    }

    static toDateString(date: Date | undefined): string | undefined {
        return moment(date).format(DATE_FORMAT)
    }

    static toUtcDateTimeString(date: Date | undefined): string | undefined {
        return moment(date).utc().format(UTC_DATETIME_FORMAT)
    }

    static toUiTimeStringWithoutSeconds(date: Date | undefined) {
        return moment(date).format(UI_TIME_FORMAT_HHMM)
    }

    static toUiTimeString(date: Date | undefined) {
        return moment(date).format(UI_TIME_FORMAT)
    }

    static mergeDateAndTime(date: Date | undefined, timeText: string | undefined): Date | undefined {
        if (!date || !timeText) {
            return undefined
        }
        date.setSeconds(0)
        date.setMilliseconds(0)
        const time = MomentUtils.fromString(timeText, UI_TIME_FORMAT_HHMM)
        if (time) {
            date.setHours(time.hour())
            date.setMinutes(time.minute())
        } else {
            date.setHours(0)
            date.setMinutes(0)
        }
        return date;
    }

    static createDateTimeString(date: string, time: string): string {
        return moment(DateUtils.mergeDateAndTime(DateUtils.fromDate(date), time)).utc().format(UTC_DATETIME_FORMAT)
    }

    static endDateIsAfterStartDate(startDate?: Date, endDate?: Date): boolean {
        if (endDate == undefined || startDate == undefined) {
            return true
        } else {
            return new Date(endDate).valueOf() > new Date(startDate).valueOf();
        }
    }
}

export function getTimeZone(): string {
    return MomentUtils.getTimeZone()
}

export function enumerateDaysBetweenDates(startDate: Moment, endDate: Moment): Array<DateOption> {
    let now = startDate.clone()
    const dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push({
            value: now.format('YYYY-MM-DD'),
            text: now.format('DD MMM YYYY')
        });
        now = now.add(1, 'days');
    }
    return dates;
}

export interface DateOption {
    text: string;
    value: string;
}

export function cleaveTimeToTime(text?: string): string | undefined {
    if (text) {
        return `${text.substr(0, 2)}:${text.substr(2, 2)}`
    }
    return undefined
}
