import {CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {RefWithName} from "@/utils/Utils";
import {
    HorseColorCode,
    HorseGender,
    HorseRef,
    StudbookRef,
    WithersHeightRef
} from "@/modules/horses/services/HorseService";
import {SportCategory} from "@/modules/competition/service/CompetitionTestsService";
import {FileContent, httpService} from "@/services/HttpService";
import {StartlistEntryRef, StartlistRef, TestBindingRef} from "@/modules/competition/service/ManageResultsService";
import {CompetitionParticipation, CompetitionPractitioner, PersonName} from "@/shared/domain/CompetitionPractitioner";
import {RegistrationRef} from "@/modules/competition/service/ManageSubscriptionService";
import {CompetitionTestPartParameters} from "@/modules/competition/service/ProtocolSettingsService";
import {SportTypeVariant} from "@/modules/federation/services/SportTypeVariantService";
import {Arena} from "@/shared/domain/Combination";

class ManageStartlistService {
    maxCourseDesignFileSize = 3 * 1024 * 1024 //3MB

    getData(ref: CompetitionRef): Promise<StartlistData> {
        return httpService.get(`/competition/manage/${ref}/startlist/metadata`)
    }

    getTestBinding(competition: CompetitionRef, bindingRef: TestBindingRef): Promise<CompetitionTestBinding> {
        return httpService.get(`/competition/manage/${competition}/startlist/${bindingRef}`)
    }

    deleteStartlistGroup(competition: CompetitionRef, bindingRef: TestBindingRef): Promise<void> {
        return httpService.delete(`/competition/manage/${competition}/startlist/${bindingRef}`)
    }

    addStartlist(competition: CompetitionRef, startlistGroupRef: string): Promise<void> {
        return httpService.post(`/competition/manage/${competition}/startlist/group/${startlistGroupRef}/startlist`, {})
    }

    deleteStartlist(competition: CompetitionRef, startlistGroupRef: string, startlistRef: string): Promise<void> {
        return httpService.delete(`/competition/manage/${competition}/startlist/group/${startlistGroupRef}/startlist/${startlistRef}`)
    }

    getTimetable(competition: CompetitionRef, practitionerRef: string, personRef?: string): Promise<Array<TimetableEntry>> {
        let path = `/competition/manage/${competition}/startlist/timetable/${practitionerRef}`
        if (personRef) {
            path = path + `?personRef=${personRef}`
        }
        return httpService.get(path)
    }

    moveStartPosition(competition: CompetitionRef, startlistRef: StartlistRef, fromIndex: number, toIndex: number): Promise<void> {
        return httpService.post(`/competition/manage/${competition}/startlist/${startlistRef}/move`, {
            fromIndex: fromIndex,
            toIndex: toIndex
        })
    }

    swapStartPosition(competition: CompetitionRef, startlistRef: StartlistRef, fromIndex: number, toIndex: number): Promise<void> {
        return httpService.post(`/competition/manage/${competition}/startlist/${startlistRef}/swap`, {
            fromIndex: fromIndex,
            toIndex: toIndex
        })
    }

    computeStartlist(competition: CompetitionRef, startlistRef: StartlistRef): Promise<void> {
        return httpService.post(`/competition/manage/${competition}/startlist/${startlistRef}/compute`, {})
    }

    getUnassignedStartlists(competition: CompetitionRef): Promise<Array<UnassignedStartlist>> {
        return httpService.get(`/competition/manage/${competition}/startlist/unassigned-startlists`)
    }

    createStartlists(competition: CompetitionRef, tests: Array<string>): Promise<void> {
        return httpService.post(`/competition/manage/${competition}/startlist/create-startlists`, {
            tests: tests
        })
    }

    createCombinedStartlists(competition: CompetitionRef, tests: Array<string>, name: string): Promise<void> {
        return httpService.post(`/competition/manage/${competition}/startlist/create-combined-startlists`, {
            tests: tests,
            name: name
        })
    }

    getUnassignedRegistrations(competition: CompetitionRef, bindingRef: TestBindingRef): Promise<Array<UnassignedPractitioner>> {
        return httpService.get(`/competition/manage/${competition}/startlist/${bindingRef}/unassigned-registrations`)
    }

    addAllRegistrations(competition: CompetitionRef, startlistRef: StartlistRef): Promise<void> {
        return httpService.post(`/competition/manage/${competition}/startlist/${startlistRef}/add-all-registrations`, {})
    }

    addRegistrations(competition: CompetitionRef, startlistRef: StartlistRef, registrations: Array<RegistrationRequest>): Promise<void> {
        return httpService.post(`/competition/manage/${competition}/startlist/${startlistRef}/add-registrations`, {
            registrations: registrations
        })
    }

    addRegistrationOnIndex(competition: CompetitionRef, startlistRef: StartlistRef, registration: RegistrationRequest): Promise<void> {
        return httpService.post(`/competition/manage/${competition}/startlist/${startlistRef}/add-registration`, registration)
    }

    addBreak(competition: CompetitionRef, startlistRef: StartlistRef, breakType: string, order: number, duration: number): Promise<void> {
        return httpService.post(`/competition/manage/${competition}/startlist/${startlistRef}/add-break`, {
            breakType: breakType,
            order: order,
            duration: duration
        })
    }

    addOpenPosition(competition: CompetitionRef, startlistRef: StartlistRef, position: number, quantity: number): Promise<void> {
        return httpService.post(`/competition/manage/${competition}/startlist/${startlistRef}/add-open-position`, {
            position: position,
            quantity: quantity
        })
    }

    removeEntry(competition: CompetitionRef, startlistRef: StartlistRef, startlistEntryRef: StartlistEntryRef): Promise<void> {
        return httpService.delete(`/competition/manage/${competition}/startlist/${startlistRef}/entries/${startlistEntryRef}`)
    }

    getStatistics(competition: CompetitionRef, startlistRef: StartlistRef): Promise<StartlistStatistics> {
        return httpService.get(`/competition/manage/${competition}/startlist/${startlistRef}/statistics`)
    }

    getLayout(competition: CompetitionRef, startlistRef: StartlistRef): Promise<StartlistLayout> {
        return httpService.get(`/competition/manage/${competition}/startlist/${startlistRef}/layout`)
    }

    applyLayout(competition: CompetitionRef, startlistRef: StartlistRef, layout: StartlistLayout): Promise<void> {
        return httpService.post(`/competition/manage/${competition}/startlist/${startlistRef}/layout`, layout)
    }

    getOtherStartlistsForLayout(competition: CompetitionRef, startlistRef: StartlistRef): Promise<Array<RefWithName>> {
        return httpService.get(`/competition/manage/${competition}/startlist/${startlistRef}/layout/from-other/startlists`)
    }

    getSolverConfigurationTemplates(competition: CompetitionRef, startlistRef: StartlistRef): Promise<Array<SolverConfigurationTemplate>> {
        return httpService.get(`/competition/manage/${competition}/startlist/${startlistRef}/layout/solver-configuration-templates`)
    }

    getTestPartParameters(competition: CompetitionRef, startlistRef: StartlistRef): Promise<StartlistTestPartParameters> {
        return httpService.get(`/competition/manage/${competition}/startlist/${startlistRef}/testpart-parameters`)
    }

    getTestJury(competition: CompetitionRef, startlistRef: StartlistRef): Promise<Array<CompetitionJury>> {
        return httpService.get(`/competition/manage/${competition}/startlist/${startlistRef}/testpart-parameters/jury-registrations`)
    }

    saveTestPartParameters(competition: CompetitionRef, startlistRef: StartlistRef, parameters: StartlistTestPartParameters): Promise<StartlistTestPartParameters> {
        return httpService.post(`/competition/manage/${competition}/startlist/${startlistRef}/testpart-parameters`, parameters)
    }

    updateJumpingCourseDesignFile(competition: CompetitionRef, startlistRef: StartlistRef, testPart: string, file: File): Promise<string> {
        return httpService.upload(`/competition/manage/${competition}/startlist/${startlistRef}/parameters/${testPart}/jumping/course-design`, file)
    }

    deleteJumpingCourseDesignFile(competition: CompetitionRef, startlistRef: StartlistRef, testPart: string): Promise<string> {
        return httpService.delete(`/competition/manage/${competition}/startlist/${startlistRef}/parameters/${testPart}/jumping/course-design`)
    }

    toggleLock(competition: CompetitionRef, startlistRef: StartlistRef, startlistEntryRef: StartlistEntryRef): Promise<boolean> {
        return httpService.post(`/competition/manage/${competition}/startlist/${startlistRef}/entries/${startlistEntryRef}/toggle-lock`, {})
    }

    getComputedStartlistParameters(competition: CompetitionRef, startlistRef: StartlistRef): Promise<ComputedStartlistParametersResponse> {
        return httpService.get(`/competition/manage/${competition}/startlist/${startlistRef}/parameters/compute`)
    }

    saveComputedStartlistParameters(competition: CompetitionRef, startlistRef: StartlistRef, apply: boolean, parameters?: ComputedStartlistParameters): Promise<ComputedStartlistParametersResponse> {
        return httpService.post(`/competition/manage/${competition}/startlist/${startlistRef}/parameters/compute?apply=${apply}`, parameters)
    }

    updateStartlistStatus(competition: CompetitionRef, startlistRef: StartlistRef, status: StartlistStatus): Promise<StartlistStatus> {
        return httpService.post(`/competition/manage/${competition}/startlist/${startlistRef}/status/${status}`, {})
    }

    getCompetitionNumberConfigurationMetadata(competition: CompetitionRef): Promise<CompetitionNumberConfigurationMetadata> {
        return httpService.get(`/competition/manage/${competition}/startlist/renumber/metadata`)
    }

    renumberCompetitionNumbers(competition: CompetitionRef, configurations: Array<CompetitionNumberConfiguration>): Promise<void> {
        return httpService.post(`/competition/manage/${competition}/startlist/renumber`, {configurations: configurations})
    }

    exportCompetitionNumbers(competition: CompetitionRef): Promise<FileContent> {
        return httpService.download(`/competition/manage/${competition}/startlist/export/competition-numbers`)
    }
}

export interface CompetitionJury {
    person: PersonName,
    preferredTime: string,
    desiredSportCategory?: RefWithName,
    actualSportCategory?: RefWithName,
    organisation: RefWithName,
    count: number
}

export interface StartlistData {
    menu: StartlistsMenu,
    testsWithoutStartlistsCount: number,
    hasAutomaticStartlists?: boolean
}

export interface StartlistsMenu {
    entries: Array<SportCategoryMenuEntry>
}

export interface SportCategoryMenuEntry {
    name: string,
    entries: Array<TestBindingMenuEntry>
}

export interface TestBindingMenuEntry {
    ref: string,
    name: string,
    startlist?: StartlistMenuData
}

export interface StartlistMenuData {
    ref: string,
    participantCount: number,
    capacity: number,
    includedInCareer: boolean,
    includedInChampionship: boolean
}

export interface UnassignedPractitioner {
    ref: RegistrationRef,
    practitioner: CompetitionPractitioner,
    outOfCompetition: boolean,
    order: number
}

export interface UnassignedStartlist {
    ref: string,
    name: string,
    testSet: RefWithName,
    hasMultipleTestSets: boolean,
    sportTypeVariant: SportTypeVariant,
    sportCategory?: SportCategory,
    valid: boolean
}

export interface CompetitionTestBinding {
    ref: string,
    name: string,
    sportTypeVariant: SportTypeVariant,
    careers: Array<RefWithName>,
    championships: Array<RefWithName>,
    startlistGroups: Array<StartlistGroup>,
}

export interface StartlistGroup {
    ref: string,
    name: string,
    unassignedRegistrations: number,
    computed: boolean,
    combined: boolean,
    startlists: Array<Startlist>
}

export interface Startlist {
    ref: string,
    computed: boolean,
    status: StartlistStatus;
    set: StartlistSet,
    entries: Array<StartlistEntry>,
    lastSolved?: string
}

export enum StartlistStatus {
    INITIAL = "INITIAL",
    DRAFT = "DRAFT",
    DRAFT_PROPOSAL = "DRAFT_PROPOSAL",
    FINAL = "FINAL",
    CLOSED = "CLOSED",
}

export interface StartlistSet {
    ref: string,
    name: string,
    order: number,
    capacity: number
}

export interface IndividualCompetitionCombination {
    competitionNumber: number,
    time: string,
    rider: RiderInfo,
    horse: HorseInfo,
    outOfCompetition: Boolean
}

export interface RegistrationRequest {
    registrationRef: string,
    order: number
}

export interface StartlistEntry {
    ref: StartlistEntryRef,
    type: StartlistEntryType,
    order: number,
    time: string,
    duration: number,
    locked: boolean,
    constraintCount: number,
    registrationStatus?: string
}

export interface StartlistEntryPositionAware extends StartlistEntry {
    position: number
}

export interface BreakStartlistEntry extends StartlistEntry {
}

export interface OpenPositionStartlistEntry extends StartlistEntryPositionAware {
}

export interface ParticipantStartlistEntry extends StartlistEntryPositionAware {
    participant: CompetitionParticipation,
    test: RefWithName
}

export function hasStartlistPosition(entry: StartlistEntry) {
    return [StartlistEntryType.OPEN_POSITION, StartlistEntryType.PARTICIPANT].includes(entry.type)
}

export enum StartlistEntryType {
    OPEN_POSITION = "OPEN_POSITION",
    BREAK = "BREAK",
    DRAGGING_BREAK = "DRAGGING_BREAK",
    EXPLORE_BREAK = "EXPLORE_BREAK",
    PARTICIPANT = "PARTICIPANT"
}

export interface RiderInfo {
    ref: string,
    name: string,
    nationality: string,
    organisation?: RefWithName
}

export interface HorseInfo {
    ref: string,
    name: string,
    isPony: boolean,
    verified: boolean,
    withersHeight?: WithersHeightRef,
    gender?: HorseGender,
    birthDate?: Date,
    studbook?: RefWithName<StudbookRef>,
    color?: HorseColorCode,
    mother?: RefWithName<HorseRef>,
    father?: RefWithName<HorseRef>
}

export interface TimetableEntry {
    entry: ParticipantStartlistEntry,
    activity: RefWithName,
    arena: RefWithName,
    secondsPrevious: number,
    secondsNext: number,
    constraints: Array<CompetitionStartlistEntryConstraint>
}

export interface CompetitionStartlistEntryConstraint {
    lastSolve: string,
    type: string,
    timeslot: string,
    conflictingTimeslot: string
}

export interface TimetableEntrySolverConstraint {
    type: string,
    conflictingTimeslot?: string
}

export enum StartlistLayoutType {
    AUTOMATIC = "AUTOMATIC",
    NATURAL = "NATURAL",
    REGISTRATION = "REGISTRATION",
    RANDOM = "RANDOM",
    REPEAT = "REPEAT",
    CONSECUTIVE = "CONSECUTIVE",
    BY_SELECTION = "BY_SELECTION",
    FROM_OTHER = "FROM_OTHER"
}

export interface StartlistLayout {
    type: StartlistLayoutType;
    runDuration: number;
    draggingFrequency: number;
    addExploreBreak: boolean;
    addDraggingBreaks: boolean;
}

export interface AutomaticStartlistLayout extends StartlistLayout {
    configuration?: string;
    customConfiguration: boolean;
    saddleupTime?: string
    saddledownTime?: string
    minTransferTime?: string
    warmupTime?: string
    cooldownTime?: string
}

export interface SolverConfigurationTemplate {
    ref: string
    name?: string
    saddleupTime?: string
    saddledownTime?: string
    minTransferTime?: string
    warmupTime?: string
    cooldownTime?: string
}

export interface BySelectionStartlistLayout {
    reversed: boolean;
}

export interface RepeatStartlistLayout {
    step: number;
}

export interface FromOtherStartlistLayout {
    fromOther: RefWithName;
}

export interface StartlistStatistics {
    totalDuration: number;
    totalBreakDuration: number;
    participantCount: number;
    openCount: number;
    draggingBreakCount: number;
    startTime: string;
    endTime: string;
    arena?: Arena;
}

export interface StartlistTestPartParameters {
    parameters: Array<CompetitionTestPartParameters>
}

export interface ComputedStartlistParametersResponse {
    startlists: Array<ComputedStartlistDescriptor>,
    // selectionTestResultIds: Array<RefWithName>,
    // sortTestResultIds: Array<RefWithName>,
    type: ComputedStartlistParametersType,
    computed?: ComputedStartlistParameters
}

export interface ComputedStartlistDescriptor {
    ref: string,
    name: string,
    testResultIds: Array<RefWithName>,
}

export interface ComputedStartlistParameters {
    type: ComputedStartlistParametersType;
    computed: boolean;
    sourceRef?: StartlistRef;
    criteriaTestResultIds: Array<string>;
    sortDirection: ComputedStartlistSortDirection;
    sortTestResultIds: Array<string>;
}

export enum ComputedStartlistParametersType {
    GENERIC = "GENERIC",
    SHOW_JUMPING = "SHOW_JUMPING",
    DRESSAGE = "DRESSAGE",
    PRESENTATION = "PRESENTATION"
}

export interface JumpingComputedStartlistParameters extends ComputedStartlistParameters {
    selectionOption: JumpingComputedStartSelectionOption;
    includeEliminated: boolean;
    includeErrors: number;
    includeTop: number;
    sortBy: JumpingComputedStartlistSortBy;
}

export interface DressageComputedStartlistParameters extends ComputedStartlistParameters {
    selectionOption: DressageComputedStartSelectionOption;
    includeEliminated: boolean;
    includePoints: number;
    includeTop: number;
    sortBy: DressageComputedStartlistSortBy;
}

export interface PresentationComputedStartlistParameters extends ComputedStartlistParameters {
    selectionOption: PresentationComputedStartSelectionOption;
    includePoints: number;
    includeTop: number;
    sortBy: PresentationComputedStartlistSortBy;
}

export enum ComputedStartlistSortDirection {
    ASCENDING = "ASCENDING",
    DESCENDING = "DESCENDING"
}

export enum JumpingComputedStartSelectionOption {
    ALL = "ALL",
    TOP = "TOP",
    TOTAL_ERRORS = "TOTAL_ERRORS"
}

export enum JumpingComputedStartlistSortBy {
    STARTORDER = "STARTORDER",
    TIME = "TIME",
    ERRORS = "ERRORS"
}

export enum DressageComputedStartSelectionOption {
    ALL = "ALL",
    TOP = "TOP",
    POINTS = "POINTS"
}

export enum DressageComputedStartlistSortBy {
    STARTORDER = "STARTORDER",
    POINTS = "POINTS"
}

export enum PresentationComputedStartSelectionOption {
    ALL = "ALL",
    TOP = "TOP",
    POINTS = "POINTS"
}

export enum PresentationComputedStartlistSortBy {
    STARTORDER = "STARTORDER",
    POINTS = "POINTS"
}

export interface CompetitionNumberConfigurationMetadata {
    configurations: Array<CompetitionNumberConfiguration>
}

export interface CompetitionNumberConfiguration {
    sportTypeVariant: RefWithName,
    binding: RefWithName,
    participations: number,
    startNumber: number
}

export const manageStartlistService = new ManageStartlistService();
