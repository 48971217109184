import {httpService} from "@/services/HttpService";
import {FederationRef} from "@/modules/federation/services/MyFederationService";

class SportTypeService {
    getSportTypes(federationRef: FederationRef | undefined = undefined): Promise<Array<SportType>> {
        return httpService.get(`/myfederation/${federationRef}/sporttypes`)
    }
}


export const sportTypeService = new SportTypeService();

export enum SportType {
    ALL = "ALL",
    SHOW_JUMPING = "SHOW_JUMPING",
    CROSS_COUNTRY = "CROSS_COUNTRY",
    DRESSAGE = "DRESSAGE",
    EVENTING = "EVENTING",
    DRIVING = "DRIVING",
    PRESENTATION = "PRESENTATION",
    AUCTION = "AUCTION",
    GENERIC = "GENERIC"
}
