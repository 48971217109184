import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {NameInput} from "@/utils/Utils";
import {
    IndividualRegistrationPractitionerRequest,
    RegistrationRequest,
    RegistrationResponse,
    TargetStartlistPosition,
    UpdateRegistrationRequest
} from "@/modules/competition/service/ManageSubscriptionService";
import {
    GetIndividualFormMetadataRequest,
    GetIndividualRegistrationOfferRequest
} from "@/modules/competition/service/ManageRegistrationService";
import {PractitionerType} from "@/shared/domain/Practitioner";
import {RegistrationHorseData} from "@/modules/competition/store/registration/common";
import {DateUtils} from "@/utils/DateUtils";

@Module({namespaced: true, name: 'competition-individual-registration-module'})
export default class CompetitionIndividualRegistrationModule extends VuexModule {
    practitionerRef: string | null = null
    registrationRef: string | null = null

    rider: NameInput | null = null
    horse: RegistrationHorseData | null = null
    organisation: NameInput | null = null

    priceProfile: string = ""
    suggestedPriceProfileRef: string = ""

    notes: string = ""
    quantity: number = 1
    outOfCompetition: boolean = false

    changedForm: boolean = false
    isUnknown: boolean = false


    @Mutation
    reset(registrationResponse: RegistrationResponse | undefined) {
        this.registrationRef = null
        this.practitionerRef = null
        this.rider = null
        this.horse = null
        this.organisation = null
        this.priceProfile = ""
        this.notes = ""
        this.suggestedPriceProfileRef = ""
        this.quantity = 1
        this.outOfCompetition = registrationResponse ? registrationResponse.defaultOutOfCompetition : false

        this.isUnknown = false
        this.changedForm = false
    }

    @Mutation
    initialize(data: {
        ref: string | null,
        practitionerRef: string | null,
        rider: NameInput | null,
        horse: RegistrationHorseData | null,
        organisation: NameInput | null,
        priceProfile: string,
        suggestedPriceProfileRef: string
        notes: string | undefined,
        outOfCompetition: boolean
    }) {
        this.registrationRef = data.ref
        this.practitionerRef = data.practitionerRef
        this.rider = data.rider
        this.horse = data.horse
        this.organisation = data.organisation
        this.priceProfile = data.priceProfile
        this.suggestedPriceProfileRef = data.suggestedPriceProfileRef
        this.notes = data.notes || ""
        this.outOfCompetition = data.outOfCompetition
        this.isUnknown = data.rider?.ref == undefined
    }

    @Mutation
    changeForm() {
        this.isUnknown = !this.isUnknown
        this.rider = null
        this.horse = null
        this.organisation = null

        this.changedForm = true
    }

    @Mutation
    updateIsUnknown(value: boolean) {
        this.isUnknown = value
    }

    @Mutation
    updateRegistrationRef(ref: string | null) {
        this.registrationRef = ref
    }

    @Mutation
    updateRider(rider: NameInput | null) {
        this.rider = rider
    }

    @Mutation
    updateHorse(horse: RegistrationHorseData | null) {
        this.horse = horse
    }

    @Mutation
    updateOrganisation(organisation: NameInput | null) {
        this.organisation = organisation
    }

    @Mutation
    updatePriceProfile(priceProfile: string) {
        this.priceProfile = priceProfile
    }

    @Mutation
    updateQuantity(n: number) {
        this.quantity = n
    }

    @Mutation
    updateOutOfCompetition(outOfCompetition: boolean) {
        this.outOfCompetition = outOfCompetition
    }

    @Mutation
    updateNotes(notes: string) {
        this.notes = notes
    }

    @Mutation
    updateSuggestedPriceProfileRef(ref: string) {
        this.suggestedPriceProfileRef = ref
    }

    @Action
    async asRequest(
        data: {
            testRef: string,
            testSetRef: string,
            confirmSaveWithWarnings: boolean,
            startPositions: Array<TargetStartlistPosition>
        }
    ): Promise<RegistrationRequest> {
        return {
            testRef: data.testRef,
            testSetRef: data.testSetRef,
            quantity: this.quantity,
            data: await this.asPractitionerRequest(),
            outOfCompetition: this.outOfCompetition,
            notes: this.notes,
            startPositions: data.startPositions,
            priceProfileRef: this.priceProfile,
            confirmSaveWithWarnings: data.confirmSaveWithWarnings
        } as RegistrationRequest
    }

    @Action
    asPractitionerRequest(): IndividualRegistrationPractitionerRequest {
        return {
            type: PractitionerType.INDIVIDUAL,
            practitionerRef: this.practitionerRef,
            isUnknown: this.isUnknown,
            person: this.rider,
            organisation: this.organisation,
            horse: this.horse,
            birthDate: this.horse?.birthDate ? DateUtils.toDateString(DateUtils.fromDate(this.horse?.birthDate)) : null,
            gender: this.horse?.gender,
            studbook: this.horse?.studbook,
            horseUeln: this.horse?.ueln,
            horsePony: this.horse?.pony,
            horseWithersHeightCode: this.horse?.withersHeightCode,
            horseFather: this.horse?.father,
            horseMother: this.horse?.mother,
            horseFatherOfMother: this.horse?.fatherOfMother
        } as IndividualRegistrationPractitionerRequest
    }

    @Action
    async asUpdateRequest(confirmSaveWithWarnings: boolean): Promise<UpdateRegistrationRequest> {
        return {
            data: await this.asPractitionerRequest(),
            outOfCompetition: this.outOfCompetition,
            notes: this.notes,
            priceProfileRef: this.priceProfile,
            confirmSaveWithWarnings: confirmSaveWithWarnings
        }
    }

    get canGetOffer(): boolean {
        if (!this.rider?.name) return false
        if (!this.horse?.name) return false
        if (this.priceProfile?.length == 0) return false
        return true
    }

    @Action
    async asOfferRequest(): Promise<GetIndividualRegistrationOfferRequest> {
        return {
            registrationRef: this.registrationRef,
            practitioner: await this.asPractitionerRequest(),
            priceProfileRef: this.priceProfile,
            quantity: this.quantity,
            outOfCompetition: this.outOfCompetition
        } as GetIndividualRegistrationOfferRequest
    }

    @Action
    async asMetadataRequest(): Promise<GetIndividualFormMetadataRequest> {
        return {
            practitioner: await this.asPractitionerRequest(),
        } as GetIndividualFormMetadataRequest
    }
}
